<template>
    <a-card :bordered="false">
      <div class="">
        <a-form layout="inline" label-align="left">
          <a-row :gutter="48">
            <a-col :md="8">
              <a-form-item label="手机号">
                <a-input v-model="queryParam.phone" placeholder="请输入"/>
              </a-form-item>
            </a-col>
            <a-col :md="8">
              <a-form-item label="账号状态">
                <a-select v-model="queryParam.account_status" style="width: 120px">
                  <a-select-option :value="0">全部</a-select-option>
                  <a-select-option :value="1">使用中</a-select-option>
                  <a-select-option :value="2">注销</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8">
              <a-form-item label="权限状态">
                <a-select v-model="queryParam.perm_status" style="width: 120px">
                  <a-select-option :value="0">全部</a-select-option>
                  <a-select-option :value="1">启用</a-select-option>
                  <a-select-option :value="2">停用</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
              <a-col :md="8">
                <a-form-item label="发布总数量">
                  <a-input-number v-model="queryParam.publish_count_min" :min="0" /> - 
                  <a-input-number v-model="queryParam.publish_count_max" :min="0" />
                </a-form-item>
              </a-col>
              <a-col :md="8">
                <a-form-item label="转发总数量">
                  <a-input-number v-model="queryParam.share_count_min" :min="0" /> - 
                  <a-input-number v-model="queryParam.share_count_max" :min="0" />
                </a-form-item>
              </a-col>
              <a-col :md="8">
                <a-form-item label="点赞总数量">
                  <a-input-number v-model="queryParam.thumb_count_min" :min="0" /> - 
                  <a-input-number v-model="queryParam.thumb_count_max" :min="0" />
                </a-form-item>
              </a-col>

            <a-col :md="8">
                <a-form-item label="注册时间">
                  <a-space direction="vertical" :size="12">
                     <a-range-picker @change="registerDateChange" />
                  </a-space>
                </a-form-item>
              </a-col>

            <a-col :md="8">
                <a-form-item label="权限操作时间">
                  <a-space direction="vertical" :size="12">
                    <a-range-picker @change="permDateChange" />
                  </a-space>
                </a-form-item>
              </a-col>

            <a-col :md="8">
              <a-form-item label="权限操作人">
                <a-input v-model="queryParam.sys_user_account" placeholder="请输入"/>
              </a-form-item>
            </a-col>

            <a-col :md="8">
              <span>
                <a-button type="primary" @click="searchLoad">查询</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <a-table
        size="default"
        rowKey="id"
        :columns="columns"
        :dataSource="data"
        :alert="true"
        :showPagination="false"
        :pagination="false"
      >
        <span slot="avatar" slot-scope="text">
          <a-avatar :src="text" />
        </span>
        

        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleStopUse(record)">停用</a>
            <a-divider type="vertical" />
            <a @click="handleStartUse(record)">启用</a>
          </template>
        </span>
      </a-table>
      <a-pagination :defaultPageSize="10" :total="total" @change="loadMore" />
    </a-card>
</template>

<script>
import request from '@/utils/request'

const columns = [
  {
    title: '序号',
    dataIndex: "id"
  },
  {
    title: 'ID',
    dataIndex: 'openid'
  },
  {
    title: '昵称',
    dataIndex: 'nickname',
  },
  {
    title: '头像',
    dataIndex: 'avatar_url',
    scopedSlots: { customRender: 'avatar' }
  },
  {
    title: '手机号',
    dataIndex: 'phone',
  },

  {
    title: '注册时间',
    dataIndex: 'authorized_at',
  },
  {
    title: '账号状态',
    dataIndex: 'account_status',
  },
  {
    title: '发布总次数',
    dataIndex: 'publish_count',
  },
  {
    title: '转发总次数',
    dataIndex: 'share_count',
  },
  {
    title: '点赞总次数',
    dataIndex: 'thumb_count',
  },
  {
    title: '权限状态',
    dataIndex: 'perm_status',
  },
  {
    title: '权限操作人',
    dataIndex: 'sys_user_account',
  },
  {
    title: '权限操作时间',
    dataIndex: 'permed_at',
  },  
  {
    title: '操作',
    dataIndex: 'action',
    width: '150px',
    scopedSlots: { customRender: 'action' }
  }
]


export default {
  name: 'User',
  components: {
  },
  data () {
    this.columns = columns
    return {
      data: [],
      total: 0,
      // 查询参数
      queryParam: {
        page: 1,
        limit: 10,
        created_start_at: '',
        created_end_at: '',
        phone: '',
        publish_count_min: 0,
        publish_count_max: 0,
        share_count_min: 0,
        share_count_max: 0,
        thumb_count_min: 0,
        thumb_count_max: 0,
        account_status: 0,
        perm_status: 0,
        permed_start_at: '',
        permed_end_at: '',
        sys_user_account: '',
      }
    }
  },
  filters: {
  },
  created () {
    this.loadData()
  },
  computed: {
  },
  methods: {
    loadMore(e){
  this.queryParam.page = e
    this.loadData()
  },
  searchLoad() {
  this.queryParam.page = 1
  this.loadData()
  },
    loadData() {
      request({
        url: "/user/list",
        method: 'get',
        params: this.queryParam
      }).then(res => {
        this.data = res.data.list
        this.total = res.data.total
      })
    },
    handleStopUse(record) {
      request({
        url: "/user/perm",
        method: 'post',
        data: {
          id: record.id,
          status: 2
        }
      }).then(res => {
      console.log(res)
        if (res.status == 1) {
            this.loadData()
        }
      })
    },
    handleStartUse (record) {
      request({
        url: "/user/perm",
        method: 'post',
        data: {
          id: record.id,
          status: 1
        }
      }).then(res => {
        if (res.status == 1) {
            this.loadData()
        }
      })
    },

    registerDateChange(data, dataString) {
      console.log(data, dataString)
      this.queryParam.created_start_at = dataString[0]
      this.queryParam.created_end_at = dataString[1]
    },

    permDateChange(data, dataString) {
    console.log(data, dataString)
      this.queryParam.permed_start_at = dataString[0]
      this.queryParam.permed_end_at = dataString[1]
    }

  }
}
</script>
